import { useState } from 'react'
import {  useNavigate } from 'react-router-dom'
import  Image  from '../../Assets'
import MyImage from 'Components/MyImage'
import LockPop from './Lockpop'
import { useView } from 'Context/viewContext'

interface menuArr{
  title?: string
  id?: string
  status?: string
}
interface Sidebar {
  menuArr: Array<menuArr>
  currentMenu: string | undefined
  setCurrentMenu: any
  isMenuActive: boolean,
  setIsMenuActive: any
}

export default function Sidebar_Program({menuArr, currentMenu,setCurrentMenu,isMenuActive,setIsMenuActive}:Sidebar) {
  const navigate = useNavigate()
  const [display, setDisplay] = useState<boolean>(false)
  const {isMobile}=useView()
  const images: { [key: string]: any } = {
    LOCKED: <img src={Image.lock} width={22} alt='alt' />,
    UNLOCKED: <img src={Image.unlock} width={22} alt='alt' />,
    COMPLETED: <img src={Image.check} width={22} alt='alt' />,
  }
  return (
    <div className={`bg-[#21132c] absolute transition-all z-[9900] duration-500 ease-in-out md:relative tailwingSidebar phone:h-full pt-4  phone:pt-6 ${isMenuActive? 'w-4/5 md:w-72' : 'w-0'} ' id="sidebar-desktop`}>
      <div className={`absolute hidden md:block top-1/2 right-[-35px] z-50 ${isMenuActive?"":"transform rotate-540"} `} onClick={()=>setIsMenuActive(!isMenuActive)}><MyImage src={Image.Sidebar_Toogle} className={` ${isMenuActive?"":"transform transition-all rotate-180"} `} loadOnPageLoad={true} /> </div>
      <LockPop display={display} setDisplay={setDisplay}/>
        <ul className='list-none overflow-hidden'>
          {menuArr.map((element, index) => {
            return (
              <li className={`${element.id === currentMenu ? "bg-[#471770] rounded-sm sidebar_active" :element.id ==='title'?'border-b-2 border-solid border-brand ':element.id==='dashboard'?` ${isMenuActive?"":"hidden"} border-t-2 w-full  border-solid border-brand absolute bottom-2 md:bottom-[5px]`:""}`} key={index}>
                <button  className='font-semibold items-center text-left text-sm phone:text-base w-full flex mb-2 mt-2 text-white p-4' onClick={() => {
                  if(isMobile) setIsMenuActive(false)
                  if(element.id === 'title' || element.status ==='LOCKED'){
                    if(element.id!=='title')
                    setDisplay(true)
                    return
                  }
                  else if(element.id === 'dashboard')
                  navigate(`/user/dashboard/course`)
                  else
                  navigate(`/program/dashboard/${element.id}`)
                }}>
                  <span className={element.id === 'title'?'flex flex-wrap text-left w-full home_banner_text_gradient font-extrabold  font-youvah ':element.id==='dashboard'?'w-full text-[#b3b3b3]':'w-full'}>{element.title}</span>
                  {element.status!=='NONE'&&<span className='flex items-center w-[20%] justify-center'>{images[element.status||'LOCKED']}</span>}
                </button>
              </li>
            )
          })}
        </ul>
      </div>
  )
}



import Image from 'Assets/index'
import { useAuth } from 'Context/authContext'
import { useNavigate } from 'react-router-dom'
import { useUser } from 'Context/userContext'
import { useEnroll } from 'Context/enrollContext'
import Notification from 'Components/Notification'
import { useView } from 'Context/viewContext'
interface Header {
  isMenuActive: boolean,
  setIsMenuActive: any
}
function Header_User({isMenuActive,setIsMenuActive}:Header) {
 const {logout} = useAuth()
 const {user} = useUser()
 const {isMobile}=useView()
 const {updateEnroll}=useEnroll()
 const navigate = useNavigate()
  return (
    <nav
        className="flex flex-row z-[9999]  h-[4.5rem] absolute top-0  items-center justify-between  w-full bg-[#14081e] pl-2 pr-4 md:pr-48"
      >
        
        <div className="md:w-48  h-[4rem]  p-3">
          <span  className='cursor-pointer'>
            <img onClick={()=>navigate('/')} id="logoimgHeader" className='w-full h-full hidden md:block overflow-hidden' src={Image.logo} alt="YouVah Logo" />
            <img onClick={()=>setIsMenuActive(!isMenuActive)} width="25" height="25" className='block mt-1 md:hidden' src={ isMenuActive ?`https://img.icons8.com/sf-black/64/FFFFFF/delete-sign.png`:`https://img.icons8.com/ios-filled/50/FFFFFF/menu--v6.png`} alt="menu--v6"/>
          </span>
        </div>

        <div
        className="gap-3 justify-end items-center text-white flex flex-row md:w-[51rem]"
      >
        {/* <a href="/">My Learning</a> */}
        <span className='shadow-custom-gray rounded-full px-2 py-2 '>
        <Notification />
        </span>
        <span className='shadow-custom-gray rounded-xl px-4 py-2 flex gap-2 justify-center items-center'>
        <a href="#" className='font-bold home_banner_text_gradient text-lg md:text-xl font-youvah'>Hello, {isMobile ? user.name?.split(" ")[0] : user.name}</a>
        <div className="dropdown">
  <button className="dropbtn">
  <img
          src={
        Image.dot
          }
         className='h-5 mt-1'
          alt=""
          
        />
  </button>
  <div className="dropdown-content">
    <a className='font-youvah text-black block font-bold' onClick={()=>{navigate('/user/dashboard/profile')}}>Profile Settings</a>
    <a className='font-youvah text-black block font-bold' onClick={()=>{
      localStorage.removeItem('user')
      localStorage.removeItem('cohort')
      localStorage.clear()
      updateEnroll({
        _id:''
      })
      logout()}}>Logout</a>
  </div>
</div>
</span>
      </div>
      </nav>
  )
}

export default Header_User

import React from 'react'
import Image from 'Assets/index'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer className="blade-top-padding text-white">
	<div
		className="blade-bottom-padding md:grid hidden text-left md:text-center w-container place-content-start md:place-content-center md:gap-5 gap-3"
	>
		<span className="font-semibold italic">Proudly backed by</span>

		<div className="grid place-content-center place-items-center">
			<img
				src={Image.IIM}
				alt="Youvah is backed by two IIM Ahmedabad and IIM Banglore"
				className="h-10 md:h-12 w-full object-contain object-center"
			/>
		</div>
	</div>
	<div
		className="w-container max-w-screen-xl flex flex-col justify-center lg:flex-row gap-8 blade-bottom-padding"
	>
		<div className="grid pt-2 flex-0 lg:basis-[22rem]">
			<div className="pb-5">
				<img
					className="h-14 md:h-12 w-auto object-contain"
					loading="lazy"
					src={Image.logo}
					alt="logo for youvah: india's first internship platform for teens"
				/>
			</div>
			<span className="text-base leading-snug md:block hidden"
				>YouVah is India&apos;s First Internship <br />Platform for teenagers. <br />
				<strong className="font-semibold">Intern now!</strong></span>
			<div
				className=" grid md:hidden blade-bottom-padding-sm w-full text-left md:text-center place-content-start md:place-content-center md:gap-5 gap-3"
			>
				<span className="font-semibold italic">Proudly backed by</span>

				<div className="grid place-content-center place-items-center">
					<img
						src={Image.IIM}
						alt="Youvah is backed by two IIMs"
						className="h-10 md:h-12 w-full object-contain object-center"
					/>
				</div>
			</div>

			<div className="md:hidden flex gap-2 pt-3">
				<a target="_blank" href="https://www.instagram.com/youvah_official/">
					<img
						loading="lazy"
						src={Image.Instagram}
						height="28"
						width="28"
						alt="visit instagram profile of Youvah- India's first internship platforms for teens"
					/></a>
				<a target="_blank" href="https://www.facebook.com/youvahofficial">
					<img
						loading="lazy"
						src={Image.Facebook}
						height="28"
						width="28"
						alt="visit facebook profile of Youvah- India's first internship platforms for teens"
					/>
				</a>
				<a target="_blank" href="https://www.linkedin.com/company/youvah/about/">
					<img
						loading="lazy"
						src={Image.Linked}
						height="28"
						width="28"
						alt="visit linkedin profile of Youvah- India's first internship platforms for teens"
					/>
				</a>
			</div>
		</div>

		<ul className="list-none flex-0 lg:basis-56 flex flex-col md:gap-[2px]">
			<li>
				<h6 className="font-semibold tracking-wide pb-2 md:pb-3">Navigation</h6>
			</li>
			<li>
				<Link
				
					to="/"
				>
					Home
				</Link>
			</li>

			<li>
				<Link
					
					to="https://www.app.youvah.com/youvahclub"
				>
					YouVah for schools
				</Link>
			</li>
			<li>
				<Link
					
					to="https://12thpass.co"
				>
					12thpass.co
				</Link>
			</li>
			<li>
				<Link
				
					to="https://www.app.youvah.com/careers">Careers</Link>
			</li>
			{/* <li>
				<Link
				
					to="https://res.cloudinary.com/dsnqeopbp/image/upload/v1699253730/Form_MGT_7A_Youvah_2022-23_arj30e.pdf"
					>Annual Report 22-23
				</Link>
			</li> */}
		</ul>
		<ul className="list-none flex-0 lg:basis-52 flex flex-col md:gap-[2px]">
			<li>
				<h6 className="font-semibold tracking-wide pb-2 md:pb-3">Support</h6>
			</li>
			<li>
				<Link to="/policy/privacy" >Privacy Policy</Link>
			</li>

			<li>
				<Link to="/policy/terms">
					Terms & Conditions
				</Link>
			</li>
			<li>
				<Link
				
					to="/policy/refund"
				>
					Refund Policy
				</Link>
			</li>
			<li>
				<Link
				
					to="https://api.whatsapp.com/send/?phone=%2B918962275730&text=Hi&type=phone_number&app_absent=0"
				>
					Contact Us
				</Link>
			</li>
		</ul>

		<div className="flex-col gap-4 lg:basis-60 lg:flex hidden">
			<span className=" text-sm md:text-base font-medium leading-snug block"
				>Follow us for latest updates <br /> and offers & more</span>
			<div className="flex gap-2">
				<a target="_blank" href="https://www.instagram.com/youvah_official/">
					<img
						loading="lazy"
						src={Image.Instagram}
						height="28"
						width="28"
						alt="visit instagram profile of Youvah- India's first internship platforms for teens"
					/></a>
				<a target="_blank" href="https://www.facebook.com/youvahofficial">
					<img
						loading="lazy"
						src={Image.Facebook}
						height="28"
						width="28"
						alt="visit facebook profile of Youvah- India's first internship platforms for teens"
					/>
				</a>
				<a target="_blank" href="https://www.linkedin.com/company/youvah/about/">
					<img
						loading="lazy"
						src={Image.Linked}
						height="28"
						width="28"
						alt="visit linkedin profile of Youvah- India's first internship platforms for teens"
					/>
				</a>
			</div>
		</div>
	</div>
	<div className="w-container max-w-screen-xl border-t-1 border-white border-opacity-20 py-4 md:py-6">
		<span
			className="text-center text-white text-[12px] md:text-sm text-opacity-80 px-4 leading-tight block font-medium"
			>&copy; 2024 YouVah. All Rights Reserved.</span>
	</div>
</footer>
  )
}

export default React.memo(Footer)
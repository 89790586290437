import { ReactElement, useEffect, useState, useMemo } from 'react';
import {  useNavigate, useParams } from 'react-router-dom';
import Sidebar from './Sidebar/Sidebar';
import Header_User from './Header/Header_User';
import Profilepop from './Profilepop';
import Sidebar_Program from './Sidebar/Sidebar_Program';
import { AllPrograms, CardDashboard, MyCourses, ProfileSetting, Resume, Lms, Challenge, Internships, 
  // PaidInternship,
   Lms_V1, 
   ReferAndEarn} from 'Pages/index';
import { useView } from 'Context/viewContext';
import { useEnroll } from 'Context/enrollContext';
import Header_Lms from './Header/Header_Lms';
import { useQueryClient } from '@tanstack/react-query';
import { useAuth } from 'Context/authContext';
import { useUser } from 'Context/userContext';

interface MenuItem {
  title?: string;
  id?: string;
  element?: ReactElement;
  status?: string;
}

export default function DashboardWrapper(): ReactElement {
  const { menu } = useParams();
  const {currentUser}=useAuth()
  const {user} = useUser();
  const queryClient = useQueryClient();
  const { isMobile } = useView();
  const { enroll } = useEnroll();
  const [currentMenu, setCurrentMenu] = useState(menu);
  const [isMenuActive, setIsMenuActive] = useState<boolean>(isMobile ? false : true);
  const isCourseDashboard = window.location.pathname.includes('program');
  const isLms = window.location.pathname.includes('learning');
  const navigate = useNavigate();
  const getSectionStatus = (section: string, step: string | undefined): string => {
    const statusMap: { [key: string]: { [key: string]: string } } = {
      assessment: {
        ASSESSMENT: 'UNLOCKED',
        INTERNSHIP: 'COMPLETED',
        DONE: 'COMPLETED',
        default: 'LOCKED'
      },
      internship: {
        INTERNSHIP: 'UNLOCKED',
        DONE: 'COMPLETED',
        default: 'LOCKED'
      },
      paid: {
        DONE: 'UNLOCKED',
        default: 'LOCKED'
      }
    };
    return statusMap[section]?.[step ?? 'default'] ?? 'LOCKED';
  };
const refetch =async()=>{
      // Use refetchQueries to trigger a refetch of specific queries
      if(!currentUser)return
      
      await queryClient.refetchQueries({ queryKey: ['courses'], type: 'active' })
      await queryClient.refetchQueries({ queryKey: ['updateProfile'], type: 'active' })
      await queryClient.refetchQueries({ queryKey: ['resume'], type: 'active' })
  
} 
useEffect(() => {
refetch()
}, [currentUser])


  const menuArr: MenuItem[] = useMemo(() => {
    if (!isCourseDashboard) {
      if(user.paymentStatus === 'PAID'){
        return [
          { title: 'My Programs', id: 'course', element: <MyCourses /> },
          { title: 'My Card', id: 'card', element: <CardDashboard /> },
          { title: 'My Resume', id: 'resume', element: <Resume /> },
          { title: 'Refer N Earn', id: 'referral', element: <ReferAndEarn /> },
          { title: 'Profile Settings', id: 'profile', element: <ProfileSetting /> },
          { title: 'Explore more Programs', id: 'all', element: <AllPrograms /> },
        ].filter(item => item !== null) as MenuItem[];
      }else{
        return [
          { title: 'All Programs', id: 'all', element: <AllPrograms /> },
          { title: 'My Programs', id: 'course', element: <MyCourses /> },
          { title: 'My Card', id: 'card', element: <CardDashboard /> },
          { title: 'My Resume', id: 'resume', element: <Resume /> },
          { title: 'Refer N Earn', id: 'referral', element: <ReferAndEarn /> },
          { title: 'Profile Settings', id: 'profile', element: <ProfileSetting /> },
        ].filter(item => item !== null) as MenuItem[];
      } 
    } else {
      if(enroll.type ==='LMS_LIVE'){
        return [
          enroll.title ? { title: enroll.title || '', id: 'title', status: 'NONE' } : {},
          { title: 'My Learning(LMS)', id: 'learning', element: <Lms_V1 />, status: 'UNLOCKED' },
          { title: '< Back to Dashboard', id: 'dashboard', status: 'NONE' },
        ].filter(item => item !== null) as MenuItem[];
    }
    else{
      return [
        enroll.title ? { title: enroll.title || '', id: 'title', status: 'NONE' } : {},
        { title: 'My Learning(LMS)', id: 'learning', element: <Lms />, status: 'UNLOCKED' },
        { title: 'My Resume', id: 'resume', element: <Resume />, status: 'UNLOCKED' },
        { title: 'My Challenge', id: 'assessment', element: <Challenge />, status: getSectionStatus('assessment', enroll.step) },
        { title: 'My Internship', id: 'internship', element: <Internships />, status: getSectionStatus('internship', enroll.step) },
        // enroll.isPaidInternshipAvailable ? { title: 'Paid Internship', id: 'alpha', element: <PaidInternship />, status: getSectionStatus('paid', enroll.step) } : null,
        { title: '< Back to Dashboard', id: 'dashboard', status: 'NONE' },
      ].filter(item => item !== null) as MenuItem[];
    }
    }
  }, [isCourseDashboard, enroll.title, enroll.step, enroll.isPaidInternshipAvailable]);
  const checkAvailability =(menu?:string):boolean=>{
    let index = 0
    index = menuArr.findIndex((obj) => obj.id == menu)
    if (index >= 0) {
      if(menuArr[index].status === 'LOCKED')
      return false
      else return true
    } else {
      if(menu ==='alpha'&& enroll.step ==='DONE')
      return true
      else 
      return false
    }
  }
  useEffect(() => {
    if (!menuArr.some(item => item.id === menu)) {
      if (isCourseDashboard &&  menu !== 'alpha') {
        navigate('/program/dashboard/learning');
        setCurrentMenu('learning');
      } else {
        navigate('/user/dashboard/all');
        setCurrentMenu('all');
      }
    } else {
      if(isCourseDashboard) {
        const check = checkAvailability(menu)
        if(check)
        setCurrentMenu(menu);
        else {
          navigate('/program/dashboard/learning');
          setCurrentMenu('learning');
        }
      }
      else
      setCurrentMenu(menu);
    }
  }, [menu]);
 

  return (
    <div className="font-youvah overflow-hidden">
      <Profilepop />
      {isLms ? (
          <Header_Lms isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive} />
        ) : (
          <Header_User isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive} />
        )}
      <div className="h-screen w-full pt-[4.5rem] flex overflow-hidden">
        {isCourseDashboard ? (
          <Sidebar_Program currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} menuArr={menuArr} isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive} />
        ) : (
          <Sidebar currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} menuArr={menuArr} isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive} />
        )}
        <div className="w-full h-full pl-0 md:pl-10 bg-[#14081e] overflow-hidden overflow-y-scroll no-scrollbar">
          {menuArr.find(menuItem => menuItem.id === currentMenu)?.element}
        </div>
      </div>
    </div>
  );
}

import  { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import  Image  from '../../Assets'
import { useAuth } from 'Context/authContext'
import { useView } from 'Context/viewContext';
import Sidebar_Home from 'Layout/Sidebar/Sidebar_Home';
import { useUser } from 'Context/userContext';
import ShinyButton from 'Components/ShinyButton';
interface MenuItem {
  title?: string;
  id?: string;
}
export default function Header({bgColor,sipNew}:any) {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const {user}=useUser()
  const { isMobile } = useView();
  const [isMenuActive, setIsMenuActive] = useState<boolean>(isMobile ? false : true);
  const menuArr: MenuItem[] = [
  {title:'Hire a Talent', id: 'hire'},
  {title:'About Us', id: '/aboutus'},
  currentUser?{title:'Dashboard >',id:'dashboard'}:{title:'Login', id: 'signin'}
  ].filter(item => item !== null) as MenuItem[];
  return (
    <header className={`border-solid border-white border-opacity-5 z-50 h-max bg-[${bgColor}]`}>
        <Sidebar_Home  menuArr={menuArr} isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive} />
      <nav className=" w-container-lg 2xl:w-container  h-[65px]  flex items-center gap-7 justify-between">
        <Link to="/" className="h-8 md:h-10">
          <img
            src={Image.logo}
            className="h-full w-auto object-contain"
            loading="eager"
            alt="logo for youvah"
          />
        </Link>
       {!sipNew&& <div className='gap-5 font-semibold items-center hidden phone:flex cursor-pointer'>
          {/* <span className="text-white">Careers</span>
          <span className="text-white">For Schools</span> */}
          <span className="text-white" onClick={()=>{
            window.open('https://youvah.fillout.com/t/qDeL9is5WAus','_blank');
          }}>Hire a Talent</span>
          <span onClick={()=>{navigate('/aboutus')}} className={` ${window.location.pathname === '/aboutus'?'text-brand underline underline-offset-4':"text-white"}`}>About Us</span>
          {<span onClick={()=>{navigate(window.location.pathname ==='/referral' ? '/signin?re=L3VzZXIvZGFzaGJvYXJkL3JlZmVycmFs':'/referral')}} className=""><ShinyButton text='Refer N Earn' /></span>}
          {currentUser ?<span onClick={()=>{navigate(user?.paymentStatus==='PAID'?'/user/dashboard/course':'/user/dashboard/all')}} className="text-white border-white border-2 border-solid rounded-md px-7 py-2">Dashboard</span>:<span onClick={()=>{navigate('/signin')}} className="text-white border-white border-2 border-solid rounded-md px-9 py-2">Login</span>}
        </div>}
        {!sipNew&&<div className='gap-5 font-semibold items-center flex phone:hidden cursor-pointer'>
          {<span onClick={()=>{navigate(window.location.pathname ==='/referral' ? '/signin?re=L3VzZXIvZGFzaGJvYXJkL3JlZmVycmFs':'/referral')}} className=""><ShinyButton text='Refer N Earn' /></span>}
        <img onClick={()=>setIsMenuActive(!isMenuActive)} width="25" height="25" className='block mt-1 md:hidden' src={ isMenuActive ?`https://img.icons8.com/sf-black/64/FFFFFF/delete-sign.png`:`https://img.icons8.com/ios-filled/50/FFFFFF/menu--v6.png`} alt="menu--v6"/>
        </div>}
      </nav>
    </header>
  )
}
